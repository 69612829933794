import { useMutation, useQueryClient } from '@tanstack/react-query'

import { useApiClient } from 'src/api/hooks/useApiClient'

interface UpdateGatewayParams {
  token: string
}

export const useUpdateLocationGateway = (locationId: number) => {
  const { getClient } = useApiClient()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ token }: UpdateGatewayParams) => {
      const client = await getClient()

      await client.put(`/locations/${locationId}/billing/gateway`, { token })
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['location-payment-methods', { locationId }],
      })
    },
  })
}
