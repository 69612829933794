import React from 'react'

import PageLayout from 'src/components/WafLayout/PageLayout'
import { useLocationContext } from 'src/contexts/LocationContext'
import { Button } from 'src/stories/Button'

const CancelAccount: React.FC = () => {
  const { locationId } = useLocationContext()

  return (
    <PageLayout
      title="Cancel Your Account"
      subtitle="We require a 30-day notice for cancellations. Click the button below to submit your cancellation request."
      baseDataAttribute="settings-cancel-account"
    >
      <Button
        shouldOpenInNewTab
        label="Request Cancellation"
        href={`https://www.signpost.com/cancellation-request?location_id=${locationId}`}
        action="danger"
        linkStyle={{
          display: 'inline-block',
        }}
      />
    </PageLayout>
  )
}

export default CancelAccount
