import { useMutation, useQueryClient } from '@tanstack/react-query'

import { useApiClient } from 'src/api/hooks/useApiClient'

interface StartKnowledgebaseUploadParams {
  documents: {
    path: string
  }[]
}

export const useCompleteKnowledgebaseUpload = (locationId: number) => {
  const { getClient } = useApiClient()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (params: StartKnowledgebaseUploadParams) => {
      const client = await getClient()

      await client.post(`/locations/${locationId}/knowledge-base`, {
        documents: params.documents,
      })
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [
          'knowledge-base',
          {
            locationId,
          },
        ],
      })
    },
  })
}
