import React, { useCallback } from 'react'
import { toast } from 'react-toastify'
import styled, { useTheme } from 'styled-components'

import PaymentInfoModal from './Modals/PaymentInfoModal'
import { PaymentMethod, useGetLocationPaymentMethods } from 'src/api'
import { useDeleteLocationPaymentMethod } from 'src/api/hooks/mutations'
import ConfirmationModal from 'src/components/Modals/ConfirmationModal'
import PageLayout from 'src/components/WafLayout/PageLayout'
import { ContentContainer } from 'src/containers/Settings/Location/Users/styled'
import { useLocationContext } from 'src/contexts/LocationContext'
import useModalNotificationsContext from 'src/contexts/ModalNotificationsContext'
import { useDebouncer } from 'src/hooks/useDebounce'
import { Button } from 'src/stories/Button'
import LoadingSpinner from 'src/stories/LoadingSpinner'
import PageSectionContainer from 'src/stories/PageSectionContainer'
import {
  AmexIcon,
  BankGenericIcon,
  CardGenericIcon,
  DiscoverIcon,
  MasterCardIcon,
  VisaIcon,
} from 'src/stories/assets'
import { Body } from 'src/stories/typography'
import logger from 'src/utils/logger'

const getCardIcon = (paymentMethod: PaymentMethod): JSX.Element => {
  if (paymentMethod.bankRoutingNumber) {
    return <BankGenericIcon />
  }

  switch (paymentMethod.cardType) {
    case 'Visa':
      return <VisaIcon />
    case 'American Express':
      return <AmexIcon />
    case 'MasterCard':
      return <MasterCardIcon />
    case 'Discover':
      return <DiscoverIcon />
    default:
      return <CardGenericIcon />
  }
}

const FlexContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
})

const IconContainer = styled.div(({ theme }) => ({
  marginRight: theme.space(2.5),
}))

const CardDetailsContainer = styled.div({
  flexGrow: 1,
})

const ButtonContainer = styled.div(({ theme }) => ({
  marginTop: theme.space(12),
  width: theme.space(57),
}))

const TitleButtonsContainer = styled.div(({ theme }) => ({
  display: 'flex',
  gap: theme.space(2),
}))

const PaymentMethodActions: React.FC<{
  onClick: () => void
  onClickDelete: () => void
  renderDelete: boolean
}> = ({ onClick, onClickDelete, renderDelete }) => {
  const theme = useTheme()

  return (
    <TitleButtonsContainer>
      {renderDelete && (
        <Button
          displayAsText
          label="Delete"
          style={{ height: theme.space(8) }}
          action="danger"
          onClick={onClickDelete}
        />
      )}
      <Button
        displayAsText
        label="Update"
        style={{ height: theme.space(8) }}
        onClick={onClick}
      />
    </TitleButtonsContainer>
  )
}

const PaymentMethods: React.FC = () => {
  const { locationId } = useLocationContext()
  const { data: paymentMethods, isLoading } =
    useGetLocationPaymentMethods(locationId)
  const {
    mutateAsync: deleteLocationPaymentMethod,
    isPending: isDeletePaymentPending,
  } = useDeleteLocationPaymentMethod(locationId)
  const baseDataAttribute = 'settings-payment-methods'
  const { showModal, closeModal } = useModalNotificationsContext()
  const theme = useTheme()

  const openPaymentInfoModal = useCallback(
    (id?: string) => {
      showModal({
        title: `Add Payment Method`,
        dataCy: 'settings-update-payment-info-modal',
        hideActionButtons: true,
        width: theme.space(110),
        customBody: (
          <PaymentInfoModal
            paymentMethodId={id}
            isUpdate={true}
            locationId={locationId}
            closeCurrentModal={closeModal}
          />
        ),
      })
    },
    [showModal, closeModal, locationId, theme]
  )

  const handleDeletePaymentMethod = useDebouncer(async (id: string) => {
    try {
      await deleteLocationPaymentMethod(id)
      closeModal()
      toast.success('Payment method deleted successfully.')
    } catch (error) {
      logger.error('MH - Error deleting payment method', { error })
      toast.error('There was an error trying to delete this payment method.')
    }
  })

  const openDeletePaymentModal = useCallback(
    (id: string) => {
      showModal({
        title: `Delete Payment Method`,
        dataCy: 'settings-delete-payment-info-modal',
        headerColor: 'accent_2',
        modalActionsOptions: {
          callToAction: {
            label: 'Delete',
            action: 'danger',
            onClick: () => handleDeletePaymentMethod(id),
            shouldDisable: () => isDeletePaymentPending,
          },
        },
        customBody: (
          <ConfirmationModal message="Are you sure you want to delete this payment method? This action cannot be undone." />
        ),
      })
    },
    [showModal, handleDeletePaymentMethod, isDeletePaymentPending]
  )

  const openAddPaymentInfoModal = useCallback(() => {
    showModal({
      title: `Add Payment Method`,
      dataCy: 'settings-update-payment-info-modal',
      hideActionButtons: true,
      width: theme.space(110),
      customBody: (
        <PaymentInfoModal
          locationId={locationId}
          closeCurrentModal={closeModal}
        />
      ),
    })
  }, [showModal, closeModal, locationId, theme])

  return (
    <PageLayout
      title="Payment Methods"
      subtitle=""
      baseDataAttribute={baseDataAttribute}
    >
      <ContentContainer>
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <>
            {!!paymentMethods?.data &&
              paymentMethods.data.map((paymentMethod, i) => {
                let exp = ''

                if (paymentMethod.expMonth && paymentMethod.expYear) {
                  const formattedMonth = paymentMethod.expMonth
                    .toString()
                    .padStart(2, '0')
                  const formattedYear = paymentMethod.expYear
                    .toString()
                    .slice(-2)

                  exp = `${formattedMonth}/${formattedYear}`
                }

                return (
                  <PageSectionContainer
                    key={i}
                    title={paymentMethod.cardType ? 'Card' : 'Bank account'}
                    subtitle=""
                    variant="inverted"
                    isRounded
                    action={PaymentMethodActions}
                    preventHiding
                    actionProps={{
                      onClick: () => {
                        openPaymentInfoModal(paymentMethod.id)
                      },
                      onClickDelete: () => {
                        if (paymentMethod.id) {
                          openDeletePaymentModal(paymentMethod.id)
                        }
                      },
                      renderDelete:
                        !!paymentMethod.id &&
                        paymentMethods.data.length > 1 &&
                        localStorage.getItem(
                          'SP_PAYMENT_METHOD_DELETE_ENABLED'
                        ) === 'true',
                    }}
                  >
                    <FlexContainer>
                      <IconContainer>
                        {getCardIcon(paymentMethod)}
                      </IconContainer>
                      <CardDetailsContainer>
                        {paymentMethod.cardType && (
                          <Body fontWeight="bolder">
                            {paymentMethod.cardType}
                          </Body>
                        )}

                        {paymentMethod.lastFour && exp && (
                          <Body>
                            Card ending in {`****${paymentMethod.lastFour}`}{' '}
                            Exp: {exp}
                          </Body>
                        )}

                        {paymentMethod.bankRoutingNumber &&
                          paymentMethod.lastFour && (
                            <Body>
                              Bank Account ending in{' '}
                              {`****${paymentMethod.lastFour}`}
                            </Body>
                          )}
                      </CardDetailsContainer>
                    </FlexContainer>
                  </PageSectionContainer>
                )
              })}
            {!!paymentMethods?.data?.length && (
              <PageSectionContainer
                title="Add Payment Method"
                variant="inverted"
                action={false}
                isRounded
              >
                <Body>
                  Add a payment method to make it easier to pay for your
                  services.
                </Body>
                <ButtonContainer>
                  <Button
                    label="Add Payment Method"
                    onClick={() => openAddPaymentInfoModal()}
                  />
                </ButtonContainer>
              </PageSectionContainer>
            )}
          </>
        )}
      </ContentContainer>
    </PageLayout>
  )
}

export default PaymentMethods
