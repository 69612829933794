import React from 'react'
import styled, { CSSObject } from 'styled-components'

export interface CheckboxButtonProps {
  label: string
  id?: string
  value: string
  group: string
  onChange: () => void
  checked: boolean
  disabled?: boolean
}

const StyledCheckboxLabel = styled.label<{ disabled?: boolean }>(
  ({ theme, disabled }) => {
    const actionPalette = theme.colors.primary_2
    const hoverPalette = theme.colors.primary_2_70
    const disabledPalette = theme.colors.base_10

    const stylesSchema: CSSObject = {
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: theme.space(2),

      padding: `${theme.space(1.5)} ${theme.space(2.5)}`,
      border: '2px solid',
      borderRadius: theme.constants.borderRadius,
      borderColor: disabled ? disabledPalette : actionPalette,
      cursor: 'pointer',
      boxSizing: 'border-box',

      fontSize: '1.2rem',
      lineHeight: '1.2rem',
      textAlign: 'center',
      outline: 'none',
      textDecoration: 'none',
      color: disabled ? disabledPalette : actionPalette,
      backgroundColor: 'transparent',
      pointerEvents: disabled ? 'none' : 'auto',

      input: {
        display: 'none',
      },

      ':hover': {
        borderColor: hoverPalette,
        color: hoverPalette,
        backgroundColor: 'transparent',
      },

      ':has(input:checked)': {
        borderColor: actionPalette,
        color: theme.colors.base_0,
        backgroundColor: actionPalette,
      },
    }

    return stylesSchema
  }
)

const CheckboxButton: React.FC<CheckboxButtonProps> = ({
  label,
  id,
  value,
  group,
  onChange,
  checked,
  disabled,
}) => {
  return (
    <StyledCheckboxLabel htmlFor={id} disabled={disabled}>
      <input
        type="checkbox"
        id={id}
        name={group}
        value={value}
        onChange={onChange}
        checked={checked}
        disabled={disabled}
      />
      {label}
    </StyledCheckboxLabel>
  )
}

export default CheckboxButton
