import { differenceInHours } from 'date-fns'
import React, { useCallback, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import styled, { useTheme } from 'styled-components'

import PreOnboardingModal from './Modals/PreOnboardingModal'
import UpdateCCInformationModal from './Modals/UpdateCCInformationModal'
import {
  ActiveConversationMessages,
  HandleTabConversationChangeFn,
  StoreActiveConversationParams,
} from './types'
import { canMarkConversationAsRead } from './utils'
import { useGetLocationPaymentMethods } from 'src/api'
import ContactDetailsPane from 'src/components/MessagingHub/ContactDetailsPane'
import ConversationsPane from 'src/components/MessagingHub/ConversationsPane'
import ConversationsActionsHeading from 'src/components/MessagingHub/ConversationsPane/ActionsHeading'
import MessagesPane from 'src/components/MessagingHub/MessagesPane'
import WafLayout from 'src/components/WafLayout'
import PaymentInfoModal from 'src/containers/Settings/PaymentMethods/Modals/PaymentInfoModal'
import useAccountContext from 'src/contexts/AccountContext'
import useAuthContext from 'src/contexts/AuthContext'
import { DEFAULT_ZERO_STATE_CONVERSATION_ID } from 'src/contexts/ConversationsListContext/utils'
import { useLocationContext } from 'src/contexts/LocationContext'
import useMhContext from 'src/contexts/MhContext'
import useModalNotificationsContext from 'src/contexts/ModalNotificationsContext'
import { Row } from 'src/stories/Layout'

const StyledMessagingHub = styled(Row)(({ theme }) => ({
  border: `1px solid ${theme.colors.base_20}`,
  borderRadius: theme.constants.borderRadius,
  background: theme.colors.base_0,
  overflow: 'hidden',
}))

const setDismissedPaymentCtaAt = () => {
  const now = new Date().toISOString()

  localStorage.setItem('DISMISSED_PAYMENT_CTA_AT', now)
}

interface MessagingHubProps {
  isMultiLocation: boolean
}

const MessagingHub: React.FC<MessagingHubProps> = ({ isMultiLocation }) => {
  const authContext = useAuthContext()
  const navigate = useNavigate()
  const theme = useTheme()

  const { search } = useLocation()
  const { locationId } = useLocationContext()
  const { showPreonboardingPopup } = useAccountContext()
  const { data: paymentMethods, isLoading: paymentMethodsIsLoading } =
    useGetLocationPaymentMethods(locationId)
  const [showPaymentInformation, setShowPaymentInformation] = useState(false)
  const { showModal, closeModal } = useModalNotificationsContext()
  const {
    showModal: showUpdateCCInformationModal,
    closeModal: closeUpdateCCInformationModal,
  } = useModalNotificationsContext()
  const {
    showModal: showPaymentInformationModal,
    closeModal: closePaymentInformationModal,
  } = useModalNotificationsContext()

  useEffect(() => {
    if (showPreonboardingPopup) {
      showModal({
        title: 'Pre-Onboarding',
        dataCy: 'pre-onboarding-modal',
        hideActionButtons: true,
        width: theme.space(100),
        customBody: (
          <PreOnboardingModal locationId={locationId} closeModal={closeModal} />
        ),
      })
    }
  }, [showPreonboardingPopup, showModal, closeModal, locationId, theme])

  useEffect(() => {
    if (!paymentMethodsIsLoading) {
      const dismissedPaymentCtaAt = localStorage.getItem(
        'DISMISSED_PAYMENT_CTA_AT'
      )
      let hasBeenDismissedLessThan24HoursAgo = false

      if (dismissedPaymentCtaAt) {
        const dismissedDate = new Date(dismissedPaymentCtaAt)
        const now = new Date()
        const diffHours = differenceInHours(now, dismissedDate)

        hasBeenDismissedLessThan24HoursAgo = diffHours < 24
      }

      if (
        !hasBeenDismissedLessThan24HoursAgo &&
        (paymentMethods?.total ?? 0) === 0
      ) {
        showUpdateCCInformationModal({
          title: 'Update your payment information',
          dataCy: 'update-cc-information-modal',
          width: theme.space(100),
          modalActionsOptions: {
            callToAction: {
              label: 'Update Payment Info',
              onClick: () => {
                setDismissedPaymentCtaAt()
                setShowPaymentInformation(true)
                closeUpdateCCInformationModal()
              },
            },
            cancel: {
              label: 'Cancel',
              onClick: () => {
                setDismissedPaymentCtaAt()
                closeUpdateCCInformationModal()
              },
            },
          },
          customBody: <UpdateCCInformationModal />,
        })
      }
    }
  }, [
    showUpdateCCInformationModal,
    closeUpdateCCInformationModal,
    theme,
    paymentMethods,
    setShowPaymentInformation,
    paymentMethodsIsLoading,
  ])

  useEffect(() => {
    if (showPaymentInformation) {
      showPaymentInformationModal({
        title: `Add Payment Method`,
        dataCy: 'settings-update-payment-info-modal',
        hideActionButtons: true,
        width: theme.space(110),
        customBody: (
          <PaymentInfoModal
            locationId={locationId}
            closeCurrentModal={closePaymentInformationModal}
          />
        ),
      })
    }
  }, [
    showPaymentInformationModal,
    closePaymentInformationModal,
    showPaymentInformation,
    locationId,
    theme,
  ])

  const {
    isDesktop,
    isMediumDesktop,
    isSidedrawerOpen,

    // Conversations Pane
    conversationsListIsLoading,
    conversations,
    openConversationId,
    isZeroState,
    mutateConversationRead,

    // Messages Pane
    openConversationContactDetails,
    setOpenConversationId,
    setIsOpenConversationChangeInFlight,
  } = useMhContext()

  // Conversations
  const [conversationsTab, setConversationTab] = useState(
    new URLSearchParams(search).get('active-tab') || 'all'
  )

  // Inputs / Message Send
  const [activeConversationMessages, setActiveConversationMessages] =
    useState<ActiveConversationMessages>({})

  const [buttonState, setButtonState] = useState(false)

  const storeActiveConversation = ({
    conversationId,
    currentText,
  }: StoreActiveConversationParams) => {
    setActiveConversationMessages((prevState) => ({
      ...prevState,
      [conversationId!]: currentText,
    }))
  }

  const handleTabConversationChange: HandleTabConversationChangeFn =
    useCallback(
      (params) => {
        const newUrl = new URLSearchParams(search)
        let newQueryParam: string

        switch (params.mode) {
          case 'tab':
            if (params.value === 'editStatuses') {
              navigate(`/${locationId}/settings/messaging-hub`)

              return
            }

            newQueryParam = 'active-tab'
            setConversationTab(params.value)
            setOpenConversationId()

            break
          case 'conversation':
            newQueryParam = 'open-conversation'
            setIsOpenConversationChangeInFlight(
              openConversationId !== params.conversationId
            )
            setOpenConversationId(params.conversationId)

            if (
              params.conversationId &&
              params.conversationEventId &&
              canMarkConversationAsRead(
                authContext.user.roles,
                params.conversationId
              )
            ) {
              void mutateConversationRead({
                locationId,
                conversationId: params.conversationId,
                conversationEventId: params.conversationEventId,
                isRead: true,
              })
            }

            break
        }

        if (
          params.mode === 'conversation' &&
          params.doNavigate &&
          params.conversationId
        ) {
          newUrl.set(newQueryParam, params.conversationId.toString())
          navigate(`?${newUrl.toString()}`)
        } else if (params.mode === 'tab') {
          newUrl.set(newQueryParam, params.value.toString())
          navigate(`?${newUrl.toString()}`)
        } else {
          newUrl.delete('open-conversation')
          navigate(`?${newUrl.toString()}`)
        }
      },
      [
        search,
        openConversationId,
        setOpenConversationId,
        authContext.user.roles,
        mutateConversationRead,
        locationId,
        navigate,
        setIsOpenConversationChangeInFlight,
      ]
    )

  const handleSidedrawerState = useCallback(
    (newState: boolean) => {
      const searchParams = new URLSearchParams(search)

      searchParams.set('open-contact-profile', newState.toString())
      setButtonState(newState)
      navigate(`?${searchParams.toString()}`)
    },
    [search, navigate]
  )

  useEffect(() => {
    if (
      conversations.length >= 1 &&
      !openConversationId &&
      !conversationsListIsLoading
    ) {
      if (isMediumDesktop) {
        handleTabConversationChange({
          mode: 'conversation',
          conversationId: conversations[0].id,
          conversationEventId: conversations[0].mostRecentEvent.id,
          doNavigate:
            !isZeroState &&
            conversations[0].id !== DEFAULT_ZERO_STATE_CONVERSATION_ID,
        })
      }
    }
  }, [
    conversations,
    openConversationId,
    isZeroState,
    handleTabConversationChange,
    isMediumDesktop,
    conversationsListIsLoading,
  ])

  return (
    <WafLayout
      styleOptions={{
        withMultiLocationHeader: isMultiLocation,
        removePadding: false,
      }}
      heading={{
        titleSlot: (
          <ConversationsActionsHeading
            handleTabConversationChange={handleTabConversationChange}
            isOpenConversation={!!openConversationId}
          />
        ),
        openSidedrawerButtonLabel: 'Contact Profile',
        sidedrawerTitle: openConversationContactDetails.name || '',
        sidedrawerContent: <ContactDetailsPane />,
        openSidedrawerButtonOnClick: () => handleSidedrawerState(true),
        closeSidedrawerButtonOnClick: () => handleSidedrawerState(false),
        isSidedrawerButtonDisabled:
          openConversationId === DEFAULT_ZERO_STATE_CONVERSATION_ID ||
          buttonState,
      }}
      isSidedrawerOpen={isSidedrawerOpen}
    >
      <StyledMessagingHub
        height={`${isMediumDesktop ? '97%' : '98%'}`}
        data-cy="messaging-hub-container"
      >
        {(isMediumDesktop || (!isMediumDesktop && !openConversationId)) && (
          <ConversationsPane
            unreadConversationsCount={
              conversations.filter((c) => !c.mostRecentEvent.isRead).length
            }
            conversationsTab={conversationsTab}
            handleTabConversationChange={handleTabConversationChange}
          />
        )}
        {/* Messages / Active Conversation */}
        {(isDesktop || (!isDesktop && !!openConversationId)) && (
          <MessagesPane
            activeConversations={activeConversationMessages}
            storeActiveConversation={storeActiveConversation}
            activeConversation={conversations.find(
              (c) => c.id === openConversationId
            )}
            handleTabConversationChange={handleTabConversationChange}
          />
        )}
      </StyledMessagingHub>
    </WafLayout>
  )
}

export default MessagingHub
