import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table'
import React, { useRef } from 'react'
import { toast } from 'react-toastify'
import { useTheme } from 'styled-components'

import DeleteKnowledgebaseDocument from './DeleteKnowledgebaseDocument'
import KnowledgebaseDetails from './KnowledgebaseDetails'
import { useUploadKnowledgebase } from './hooks/useUploadKnowledgebase'
import {
  Knowledgebase as KnowledgeBaseInterface,
  useGetKnowledgebase,
} from 'src/api'
import { StyledZeroStateTextContainer } from 'src/components/Contacts/styled'
import PageLayout from 'src/components/WafLayout/PageLayout'
import { useLocationContext } from 'src/contexts/LocationContext'
import { Button } from 'src/stories/Button'
import LoadingSpinner from 'src/stories/LoadingSpinner'
import PageSectionContainer from 'src/stories/PageSectionContainer'
import { UploadFileIcon } from 'src/stories/assets'
import { Table, TBody, Td, Th, THead, Tr } from 'src/stories/table'
import { Body } from 'src/stories/typography'

const columnHelper = createColumnHelper<KnowledgeBaseInterface>()

const columns = [
  columnHelper.display({
    header: 'Name',
    enableSorting: false,
    cell: ({ row: { original } }) => {
      return (
        <Body
          as="a"
          // @ts-expect-error Property is not defined on props
          href={original.url}
          target="_blank"
          rel="noreferrer"
          customColor={original.url ? 'primary_2' : undefined}
        >
          {original.fileName}
        </Body>
      )
    },
  }),
  columnHelper.display({
    header: '',
    id: 'action',
    enableSorting: false,
    cell: ({ row: { original } }) => {
      return <DeleteKnowledgebaseDocument documentId={original.id} />
    },
  }),
]

const KnowledgeBase = () => {
  const theme = useTheme()
  const { locationId } = useLocationContext()
  const baseDataAttribute = 'settings-location-knowledge-base'
  const fileInputRef = useRef<HTMLInputElement>(null)
  const { uploadKnowledgebase, isLoading: isUploading } =
    useUploadKnowledgebase()
  const { data, isLoading, isFetching, isPending } = useGetKnowledgebase({
    locationId,
  })
  const knowledgeBase = data?.data || []
  const table = useReactTable<KnowledgeBaseInterface>({
    columns,
    data: knowledgeBase,
    getCoreRowModel: getCoreRowModel(),
  })
  const isLoadingData = isLoading || isFetching || isPending

  return (
    <PageLayout
      title="Manage your AI Knowledgebase"
      subtitle="Upload, edit, or update your knowledgebase to keep conversations sharp and accurate."
      baseDataAttribute={baseDataAttribute}
    >
      <PageSectionContainer
        title="AI Voice Knowledgebase"
        titleProps={{
          as: 'h3',
          size: 'medium',
        }}
        action={false}
        variant="inverted"
        isRounded
        childrenStyle={{
          gap: theme.space(3),
        }}
      >
        <KnowledgebaseDetails />
        <Table data-cy={baseDataAttribute + '-table'} rounded>
          <THead data-cy={baseDataAttribute + '-table-header'}>
            {table.getHeaderGroups().map((group) => (
              <Tr backgroundColor="base_10" key={group.id}>
                {group.headers.map((header) => (
                  <Th
                    key={header.id}
                    style={{ width: header.getContext().column.getSize() }}
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </Th>
                ))}
              </Tr>
            ))}
          </THead>
          <TBody data-cy={baseDataAttribute + '-table-body'}>
            {table.getRowModel().rows.length ? (
              table.getRowModel().rows.map((row) => (
                <Tr key={row.id}>
                  {row.getVisibleCells().map((cell) => {
                    return (
                      <Td key={cell.id} padding={4}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </Td>
                    )
                  })}
                </Tr>
              ))
            ) : (
              <Tr>
                <Td colSpan={7}>
                  {isLoadingData ? (
                    <LoadingSpinner logoHeight={theme.space(15)} />
                  ) : (
                    <StyledZeroStateTextContainer>
                      No results
                    </StyledZeroStateTextContainer>
                  )}
                </Td>
              </Tr>
            )}
          </TBody>
        </Table>
      </PageSectionContainer>
      <input
        type="file"
        style={{ display: 'none' }}
        ref={fileInputRef}
        multiple
        accept=".pdf,.docx,.txt"
        onChange={async ({ target: { files } }) => {
          try {
            await uploadKnowledgebase(files)
          } catch {
            toast.error('Failed to upload knowledgebase documents')
          }

          if (fileInputRef.current) {
            fileInputRef.current.value = ''
          }
        }}
      />
      <Button
        outline
        icon={UploadFileIcon}
        label="Select a File to Upload"
        style={{ maxWidth: theme.space(50), marginTop: theme.space(3) }}
        onClick={() => fileInputRef.current?.click()}
        disabled={isUploading}
      />
    </PageLayout>
  )
}

export default KnowledgeBase
