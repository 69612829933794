import React, { memo, useState } from 'react'
import styled from 'styled-components'

import Feedback from './Feedback'
import { TypedConversationEvent } from 'src/api'
import useAuthContext from 'src/contexts/AuthContext'
import { Button } from 'src/stories/Button'
import { Body } from 'src/stories/typography'
import { isInternalUser, toTitleCase } from 'src/utils'

const Container = styled.div((props) => ({
  display: 'flex',
  flexDirection: 'column',
}))

const Section = styled.div((props) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
}))

const StyledUl = styled.ul(({ theme }) => ({
  paddingLeft: theme.space(3),
  margin: 0,
}))

interface Props {
  message: TypedConversationEvent<'PHONELY_EVENT'>
}

const PhonelyMessageContent: React.FC<Props> = memo(({ message }) => {
  const [seeTranscript, setSeeTranscript] = useState(false)
  const { user } = useAuthContext()
  const internalUser = isInternalUser(user)
  const {
    details: { transcript = [], summary, keyPoints = [], agentName } = {},
  } = message

  return (
    <Container>
      <Section>
        <Body color="darker" fontWeight="bold">
          {agentName}
        </Body>
      </Section>
      <Section color="dark">
        <Body>{summary}</Body>
      </Section>
      {keyPoints.length > 0 && (
        <Section>
          <Body color="darker" fontWeight="bold">
            Key Points:
          </Body>
          <StyledUl>
            {keyPoints.map((keyPoint) => (
              <Body as="li" key={keyPoint}>
                {keyPoint}
              </Body>
            ))}
          </StyledUl>
        </Section>
      )}
      <Section>
        <Body color="darker" fontWeight="bold">
          Transcript:
        </Body>
        <Button
          label={seeTranscript ? 'Hide Transcript' : 'Show Transcript'}
          asLink
          displayAsText
          style={{ width: 'unset', justifyContent: 'flex-start' }}
          onClick={() => setSeeTranscript((s) => !s)}
        />
        {seeTranscript &&
          transcript.map((field, id) => {
            const strValue = (field.content as string | undefined) ?? ''

            return (
              <Body key={id} as="span" color="dark" fontWeight="bold">
                {toTitleCase(field.role)}: <Body as="span">{strValue}</Body>
              </Body>
            )
          })}
      </Section>
      <Feedback
        eventId={message.id}
        disabled={!!message.feedbackId || internalUser}
      />
    </Container>
  )
})

export default PhonelyMessageContent
