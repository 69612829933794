import styled from 'styled-components'

import { Button } from 'src/stories/Button'
import { Body, Heading } from 'src/stories/typography'

const StyledContainer = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'start',
  gap: theme.space(5),
}))

const PreOnboardingModal: React.FC<{
  closeModal: () => void
  locationId: number
}> = ({ locationId, closeModal }) => {
  return (
    <StyledContainer>
      <Heading size="extraLarge" customColor="primary_1">
        Welcome to Signpost
      </Heading>
      <Body>
        Let's get started by gathering the essentials needed for your live
        receptionist and messaging hub, and begin to customize your script to
        ensure every call is handled exactly the way you want.
      </Body>
      <Button
        linkStyle={{
          width: '100%',
        }}
        shouldOpenInNewTab={true}
        href={`https://www.signpost.com/pre-onboarding?location_id=${locationId}`}
        onClick={() => closeModal()}
        label="Get Started"
      />
    </StyledContainer>
  )
}

export default PreOnboardingModal
