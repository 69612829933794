import styled, { useTheme } from 'styled-components'

import NegativeFeedbackModal from './Modals/NegativeFeedbackModal'
import PositiveFeedbackModal from './Modals/PositiveFeedbackModal'
import useModalNotificationsContext from 'src/contexts/ModalNotificationsContext'
import { Button } from 'src/stories/Button'
import {
  FeedbackConversationEventIcon,
  ThumbDownIcon,
  ThumbUpIcon,
} from 'src/stories/assets'
import useScreenSizes from 'src/stories/hooks/useScreenSizes'
import { Body } from 'src/stories/typography'

const FeedbackSection = styled.div<{ isDesktop: boolean }>(
  ({ theme, isDesktop }) => ({
    display: 'flex',
    flexDirection: isDesktop ? 'row' : 'column',
    alignItems: 'center',
    marginTop: theme.space(5),
    gap: theme.space(3),
  })
)

const StyledButtonsContainer = styled.div(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  gap: theme.space(3),
}))

interface FeedbackProps {
  eventId: number
  disabled: boolean
}

const Feedback: React.FC<FeedbackProps> = ({ eventId, disabled }) => {
  const { showModal } = useModalNotificationsContext()
  const theme = useTheme()
  const { isMediumScreen: isDesktop } = useScreenSizes()

  const handleOpenPositiveFeedbackModal = () => {
    showModal({
      title: 'Leave Feedback',
      dataCy: 'feedback-modal',
      hideActionButtons: true,
      customBody: (
        <PositiveFeedbackModal
          eventId={eventId}
          dataCy="positive-feedback-modal"
        />
      ),
    })
  }

  const handleOpenNegativeFeedbackModal = () => {
    showModal({
      title: 'Leave Feedback',
      dataCy: 'feedback-modal',
      hideActionButtons: true,
      customBody: (
        <NegativeFeedbackModal
          eventId={eventId}
          dataCy="negative-feedback-modal"
        />
      ),
    })
  }

  return (
    <FeedbackSection isDesktop={isDesktop}>
      <Body>
        <FeedbackConversationEventIcon
          fill={theme.colors.base_50}
          strokeWidth={0.5}
        />
        {'  '}
        How would you rate this call?
      </Body>
      <StyledButtonsContainer>
        <Button
          displayAsText={true}
          label={<ThumbUpIcon />}
          onClick={handleOpenPositiveFeedbackModal}
          disabled={disabled}
        />
        <Button
          displayAsText={true}
          label={<ThumbDownIcon />}
          onClick={handleOpenNegativeFeedbackModal}
          disabled={disabled}
        />
      </StyledButtonsContainer>
    </FeedbackSection>
  )
}

export default Feedback
