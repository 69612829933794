import { yupResolver } from '@hookform/resolvers/yup'
import { Controller, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import * as yup from 'yup'

import {
  Form,
  StyledButtonsContainer,
  StyledContainer,
} from './StyledComponents'
import {
  FeedbackReaction,
  useCreateFeedback,
} from 'src/api/hooks/mutations/useCreateFeedback'
import useConversationsListContext from 'src/contexts/ConversationsListContext'
import { useLocationContext } from 'src/contexts/LocationContext'
import useModalNotificationsContext from 'src/contexts/ModalNotificationsContext'
import { Button } from 'src/stories/Button'
import CheckboxButtonGroup, {
  CheckboxGroupVariants,
} from 'src/stories/CheckboxButtonGroup'
import TextArea from 'src/stories/TextArea'
import { StyledLabel } from 'src/stories/commonStyles'
import { Body, Heading } from 'src/stories/typography'
import logger from 'src/utils/logger'

enum FeedbackCategories {
  DID_NOT_FOLLOW_SCRIPTS_INSTRUCTIONS = 'did-not-follow-script-instructions',
  SCHEDULED_INCORRECTLY = 'scheduled-incorrectly',
  RECEPTIONIST_DEMEANOR = 'receptionist-demeanor',
}

const formSchema = yup.object({
  feedback: yup.string().required('Feedback is required'),
  categories: yup.array(yup.string()),
})

type FormSchema = yup.InferType<typeof formSchema>

interface FeedbackModalProps {
  eventId: number
  dataCy: string
}

const NegativeFeedbackModal: React.FC<FeedbackModalProps> = ({
  eventId,
  dataCy,
}) => {
  const { closeModal } = useModalNotificationsContext()
  const { locationId } = useLocationContext()
  const { openConversationId } = useConversationsListContext()
  const { mutateAsync: createFeedback } = useCreateFeedback(locationId)

  const {
    handleSubmit,
    formState: { errors, isSubmitting },
    control,
    register,
  } = useForm<FormSchema>({
    resolver: yupResolver(formSchema),
  })

  const onSubmit = handleSubmit(
    async ({ feedback, categories }: FormSchema) => {
      try {
        if (!openConversationId) {
          throw new Error('No open conversation ID found')
        }

        await createFeedback({
          conversationId: openConversationId,
          reaction: FeedbackReaction.NEGATIVE,
          categories: (categories || []) as string[],
          eventId,
          feedback,
        })

        toast.success('Feedback submitted')
        closeModal()
      } catch (error) {
        logger.error('Failed to submit feedback', { error })
        toast.error('Failed to submit feedback')
      }
    }
  )

  const baseInputProps = {
    errors,
    verticallySpaced: false,
  }

  const categoriesOptions = [
    {
      id: FeedbackCategories.DID_NOT_FOLLOW_SCRIPTS_INSTRUCTIONS,
      value: FeedbackCategories.DID_NOT_FOLLOW_SCRIPTS_INSTRUCTIONS,
      label: 'Did not follow script/instructions',
    },
    {
      id: FeedbackCategories.SCHEDULED_INCORRECTLY,
      value: FeedbackCategories.SCHEDULED_INCORRECTLY,
      label: 'Scheduled incorrectly',
    },
    {
      id: FeedbackCategories.RECEPTIONIST_DEMEANOR,
      value: FeedbackCategories.RECEPTIONIST_DEMEANOR,
      label: 'Receptionist demeanor',
    },
  ]

  return (
    <StyledContainer>
      <Heading size="extraLarge" as={'h2'}>
        Submit your Feedback
      </Heading>
      <Body>
        This feedback will be sent directly to the Signpost team to improve your
        answering service experience
      </Body>
      <Form id={dataCy} onSubmit={onSubmit}>
        <div>
          <StyledLabel>
            Select as many topics as you want to quickly categorize your
            feedback.
          </StyledLabel>
          <Controller
            control={control}
            name="categories"
            render={({ field }) => (
              <CheckboxButtonGroup
                initialValues={[]}
                group="categories"
                checkboxesOptions={categoriesOptions}
                variant={CheckboxGroupVariants.HORIZONTAL}
                getValues={(values: string[]) => {
                  field.onChange(values)
                  field.onBlur()
                }}
              />
            )}
          />
        </div>
        <TextArea
          label="Suggestions for improvement?"
          placeholder="Please share your thoughts..."
          rows={6}
          {...register('feedback')}
          {...baseInputProps}
        />
        <StyledButtonsContainer>
          <Button
            action="secondary"
            type="button"
            label="Cancel"
            disabled={isSubmitting}
            onClick={closeModal}
          />

          <Button
            action="primary"
            type="submit"
            label="Submit Feedback"
            disabled={isSubmitting}
          />
        </StyledButtonsContainer>
      </Form>
    </StyledContainer>
  )
}

export default NegativeFeedbackModal
