import { yupResolver } from '@hookform/resolvers/yup'
import { useRef, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import styled from 'styled-components'
import * as yup from 'yup'

import CreditCardCVV from './CreditCardCvv'
import CreditCardMonth from './CreditCardMonth'
import CreditCardNumber from './CreditCardNumber'
import CreditCardYear from './CreditCardYear'
import { StyledContent } from 'src/components/Settings/Business/styled'
import { Button } from 'src/stories/Button'
import Input from 'src/stories/Input'
import RadioButtonGroup, {
  RadioButtonGroupVariants,
} from 'src/stories/RadioButtonGroup'
import Select from 'src/stories/Select'
import { StyledLabel } from 'src/stories/commonStyles'
import useScreenSizes from 'src/stories/hooks/useScreenSizes'

export enum PaymentMethodType {
  CREDIT_CARD = 'CREDIT_CARD',
  BANK_ACCOUNT = 'BANK_ACCOUNT',
}

enum AccountType {
  CHECKING = 'checking',
  SAVINGS = 'savings',
}

const paymentMethodFormSchema = yup.object({
  type: yup
    .mixed<PaymentMethodType | ''>()
    .required('Payment type is required')
    .oneOf(Object.values(PaymentMethodType), 'Invalid payment type')
    .default(''),
  zipCode: yup
    .string()
    .required('ZIP code is required')
    .zipCode('Invalid ZIP code')
    .default(null),
  creditCardNumber: yup.string().when('type', {
    is: PaymentMethodType.CREDIT_CARD,
    then: yup
      .string()
      .required('Credit card number is required')
      .min(15, 'Invalid credit card number')
      .max(16, 'Invalid credit card number')
      .default(''),
    otherwise: yup.string().notRequired(),
  }),
  cvv: yup.string().when('type', {
    is: PaymentMethodType.CREDIT_CARD,
    then: yup
      .string()
      .required('CVV is required')
      .max(4, 'Invalid CVV')
      .min(3, 'Invalid CVV')
      .default(''),
    otherwise: yup.string().notRequired(),
  }),
  month: yup.string().when('type', {
    is: PaymentMethodType.CREDIT_CARD,
    then: yup
      .string()
      .required('Expiration month is required')
      .length(2, 'Invalid month')
      .default(''),
    otherwise: yup.string().notRequired(),
  }),
  year: yup.string().when('type', {
    is: PaymentMethodType.CREDIT_CARD,
    then: yup
      .string()
      .required('Expiration year is required')
      .length(2, 'Invalid year')
      .default(''),
    otherwise: yup.string().notRequired(),
  }),
  firstName: yup.string().when('type', {
    is: PaymentMethodType.CREDIT_CARD,
    then: yup
      .string()
      .required('First name is required')
      .max(50, 'First name should be 50 characters max')
      .default(''),
    otherwise: yup.string().notRequired(),
  }),
  lastName: yup.string().when('type', {
    is: PaymentMethodType.CREDIT_CARD,
    then: yup
      .string()
      .required('Last name is required')
      .max(50, 'Last name should be 50 characters max')
      .default(''),
    otherwise: yup.string().notRequired(),
  }),
  company: yup.string().when('type', {
    is: PaymentMethodType.BANK_ACCOUNT,
    then: yup
      .string()
      .required('Company name is required')
      .max(100, 'Company name should be 100 characters max')
      .default(''),
    otherwise: yup.string().notRequired(),
  }),
  nameOnAccount: yup.string().when('type', {
    is: PaymentMethodType.BANK_ACCOUNT,
    then: yup
      .string()
      .required('Account name is required')
      .max(255, 'Account name should be 255 characters max')
      .default(''),
    otherwise: yup.string().notRequired(),
  }),
  routingNumber: yup.string().when('type', {
    is: PaymentMethodType.BANK_ACCOUNT,
    then: yup
      .string()
      .required('Routing number is required')
      .max(15, 'Invalid routing number')
      .default(''),
    otherwise: yup.string().notRequired(),
  }),
  accountNumber: yup.string().when('type', {
    is: PaymentMethodType.BANK_ACCOUNT,
    then: yup
      .string()
      .required('Account number is required')
      .max(255, 'Account number should be 255 characters max')
      .default(''),
    otherwise: yup.string().notRequired(),
  }),
  confirmAccountNumber: yup.string().when('type', {
    is: PaymentMethodType.BANK_ACCOUNT,
    then: yup
      .string()
      .oneOf([yup.ref('accountNumber'), null], 'Account numbers must match')
      .required('Confirm account number is required')
      .max(255, 'Confirm account number should be 255 characters max')
      .default(''),
    otherwise: yup.string().notRequired(),
  }),
  accountType: yup.string().when('type', {
    is: PaymentMethodType.BANK_ACCOUNT,
    then: yup
      .string()
      .required('Account type is required')
      .oneOf(
        [AccountType.CHECKING, AccountType.SAVINGS],
        'Invalid account type'
      ),
    otherwise: yup.string().notRequired(),
  }),
})

type PaymentMethodFormSchema = yup.InferType<typeof paymentMethodFormSchema>

const StyledForm = styled.form(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.space(5),
}))

const StyledInputContainer = styled.form(({ theme }) => ({
  display: 'flex',
  width: '100%',
  flexDirection: 'column',
  gap: theme.space(1),
  '.recurly-element, .recurly-hosted-field': {
    width: '100%',
    height: theme.space(11),
    padding: `0 ${theme.space(3)}`,
    backgroundColor: theme.colors.base_0,
    color: theme.colors.base_100,
    border: `1px solid ${theme.colors.base_20}`,
    borderRadius: theme.constants.borderRadius,
    fontSize: '1.5rem',
  },
  '.recurly-element-focus, .recurly-hosted-field-focus': {
    color: theme.colors.base_100,
    outline: 'none',
    border: `2px solid ${theme.colors.primary_1}`,
  },
  '.recurly-element-invalid, .recurly-hosted-field-invalid': {
    border: `2px solid ${theme.colors.accent_2}`,
    color: theme.colors.accent_2,
  },
}))

const FlexContainer = styled.div<{ isDesktop: boolean; reverse?: boolean }>(
  ({ isDesktop, reverse }) => ({
    display: 'flex',
    flexDirection: isDesktop ? 'row' : reverse ? 'column-reverse' : 'column',
    width: '100%',
    gap: '1rem',
    '& > *': {
      flex: isDesktop ? '1' : 'unset',
    },
  })
)

const RecurlyExpirationDate = styled.div(({ theme }) => ({
  display: 'flex',
  gap: 0,
  padding: 0,
  margin: 0,
  '.recurly-element-month, .recurly-hosted-field-month': {
    padding: `0 ${theme.space(2)}`,
    borderRightWidth: theme.space(0.25),
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
  '.recurly-element-year, .recurly-hosted-field-year': {
    padding: `0 ${theme.space(2)}`,
    borderLeftWidth: theme.space(0.25),
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  },
}))

const PaymentInfoForm: React.FC<{
  loading: boolean
  closeCurrentModal: () => void
  onSubmit: (form: HTMLFormElement, type: PaymentMethodType) => Promise<void>
}> = ({ loading, closeCurrentModal, onSubmit }) => {
  const { isMediumScreen: isDesktop } = useScreenSizes()
  const formRef = useRef<HTMLFormElement>(null)
  const {
    handleSubmit,
    register,
    control,
    watch,
    formState: { isSubmitting, errors: formErrors },
  } = useForm<PaymentMethodFormSchema>({
    resolver: yupResolver(paymentMethodFormSchema),
  })

  const onHandleSubmit = handleSubmit(async (data: PaymentMethodFormSchema) => {
    if (!formRef.current) return
    await onSubmit(formRef.current, data.type as PaymentMethodType)
  })

  const [accountType, setAccountType] = useState(AccountType.CHECKING)
  const paymentMethodType = watch('type')
  const disableForm = isSubmitting || loading

  const PaymentTypeOptions = [
    {
      label: 'Credit Card',
      value: PaymentMethodType.CREDIT_CARD,
    },
    {
      label: 'Bank Account',
      value: PaymentMethodType.BANK_ACCOUNT,
    },
  ]

  const AccountTypeOptions = [
    {
      id: AccountType.CHECKING,
      label: 'Checking',
      value: AccountType.CHECKING,
      disabled: false,
    },
    {
      id: AccountType.SAVINGS,
      label: 'Savings',
      value: AccountType.SAVINGS,
      disabled: false,
    },
  ]

  return (
    <StyledForm
      data-cy="update-payment-info-form"
      ref={formRef}
      onSubmit={onHandleSubmit}
    >
      <StyledContent>
        <Controller
          control={control}
          name="type"
          render={({ field }) => (
            <Select
              label="Payment Type:"
              options={PaymentTypeOptions}
              placeholder="Please Select"
              disabled={!!disableForm}
              onChange={(value) => {
                field.onChange(value)
                field.onBlur()
              }}
              initialValue={field.value}
            />
          )}
        />
        {paymentMethodType === PaymentMethodType.BANK_ACCOUNT && (
          <>
            <Input
              label="Company Name:"
              disabled={disableForm}
              verticallySpace={2}
              errors={formErrors}
              {...register('company')}
            />
            <Input
              label="Account Name:"
              disabled={disableForm}
              verticallySpace={2}
              errors={formErrors}
              data-recurly="name_on_account"
              {...register('nameOnAccount')}
            />
            <Input
              label="Routing Number:"
              disabled={disableForm}
              verticallySpace={2}
              errors={formErrors}
              data-recurly="routing_number"
              {...register('routingNumber')}
            />
            <FlexContainer isDesktop={isDesktop}>
              <Input
                label="Account Number:"
                disabled={disableForm}
                verticallySpace={2}
                errors={formErrors}
                data-recurly="account_number"
                {...register('accountNumber')}
              />
              <Input
                label="Confirm Account Number:"
                disabled={disableForm}
                verticallySpace={2}
                errors={formErrors}
                data-recurly="account_number_confirmation"
                {...register('confirmAccountNumber')}
              />
            </FlexContainer>
            <FlexContainer isDesktop={isDesktop}>
              <RadioButtonGroup
                group="account_type"
                variant={RadioButtonGroupVariants.HORIZONTAL}
                initialValue={AccountType.CHECKING}
                buttonsOptions={AccountTypeOptions}
                getValue={(value) => setAccountType(value as AccountType)}
              />
              <input
                type="hidden"
                value={accountType}
                data-recurly="account_type"
                {...register('accountType')}
              />
            </FlexContainer>
          </>
        )}
        {paymentMethodType === PaymentMethodType.CREDIT_CARD && (
          <>
            <FlexContainer isDesktop={isDesktop}>
              <Input
                label="First:"
                disabled={disableForm}
                verticallySpace={2}
                errors={formErrors}
                data-recurly="first_name"
                {...register('firstName')}
              />
              <Input
                label="Last:"
                disabled={disableForm}
                verticallySpace={2}
                errors={formErrors}
                data-recurly="last_name"
                {...register('lastName')}
              />
            </FlexContainer>
            <StyledInputContainer>
              <StyledLabel>Card Number:</StyledLabel>

              <Controller
                control={control}
                name="creditCardNumber"
                render={({ field }) => (
                  <CreditCardNumber
                    name="creditCardNumber"
                    errors={formErrors}
                    onChange={(value) => {
                      field.onChange(value)
                      field.onBlur()
                    }}
                  />
                )}
              />
            </StyledInputContainer>
            <FlexContainer isDesktop={isDesktop}>
              <StyledInputContainer>
                <StyledLabel>Exp. Date:</StyledLabel>

                <RecurlyExpirationDate>
                  <Controller
                    control={control}
                    name="month"
                    render={({ field }) => (
                      <CreditCardMonth
                        name="month"
                        errors={formErrors}
                        onChange={(value) => {
                          field.onChange(value)
                          field.onBlur()
                        }}
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name="year"
                    render={({ field }) => (
                      <CreditCardYear
                        name="year"
                        errors={formErrors}
                        onChange={(value) => {
                          field.onChange(value)
                          field.onBlur()
                        }}
                      />
                    )}
                  />
                </RecurlyExpirationDate>
              </StyledInputContainer>
              <StyledInputContainer>
                <StyledLabel>CVV:</StyledLabel>

                <Controller
                  control={control}
                  name="cvv"
                  render={({ field }) => (
                    <CreditCardCVV
                      name="cvv"
                      errors={formErrors}
                      onChange={(value) => {
                        field.onChange(value)
                        field.onBlur()
                      }}
                    />
                  )}
                />
              </StyledInputContainer>
            </FlexContainer>
          </>
        )}
        {paymentMethodType && (
          <FlexContainer isDesktop={isDesktop}>
            <Select
              label="Country:"
              options={[{ label: 'United States', value: 'us' }]}
              disabled={true}
            ></Select>
            <input type="hidden" value="us" data-recurly="country" />
            <Input
              label="Billing ZIP:"
              verticallySpace={4}
              disabled={disableForm}
              errors={formErrors}
              data-recurly="postal_code"
              {...register('zipCode')}
            />
          </FlexContainer>
        )}
      </StyledContent>
      <FlexContainer isDesktop={isDesktop} reverse={true}>
        <Button label="Cancel" action="secondary" onClick={closeCurrentModal} />
        <Button
          type="submit"
          disabled={!paymentMethodType || disableForm}
          loading={disableForm}
          label="Update Payment Information"
        />
      </FlexContainer>
    </StyledForm>
  )
}

export default PaymentInfoForm
