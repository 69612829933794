import React, { useCallback } from 'react'
import { toast } from 'react-toastify'
import styled from 'styled-components'

import { useDeleteKnowledgebase } from 'src/api'
import { useLocationContext } from 'src/contexts/LocationContext'
import useModalNotificationsContext from 'src/contexts/ModalNotificationsContext'
import { Button } from 'src/stories/Button'
import { Body } from 'src/stories/typography'

const Container = styled.div(({ theme }) => ({
  margin: `${theme.space(1)} 0 ${theme.space(4)} 0`,
  alignSelf: 'start',
}))

interface Props {
  documentId: number
}

const DeleteKnowledgebaseDocument: React.FC<Props> = ({ documentId }) => {
  const baseDataAttribute = 'settings-location-knowledge-base-delete-document'
  const { showModal, closeModal } = useModalNotificationsContext()
  const { locationId } = useLocationContext()
  const { mutateAsync: deleteKnowledgebase, isPending } =
    useDeleteKnowledgebase(locationId)

  const onClickDelete = useCallback(() => {
    showModal({
      title: 'Delete Document',
      dataCy: `${baseDataAttribute}-delete-document-${documentId}`,
      headerColor: 'accent_2',
      height: 'auto',
      modalActionsOptions: {
        callToAction: {
          label: 'Delete',
          action: 'danger',
          shouldDisable: () => {
            return isPending
          },
          onClick: async () => {
            try {
              await deleteKnowledgebase({ documentId })
            } catch {
              toast.error('Failed to delete document')
            } finally {
              closeModal()
            }
          },
        },
      },
      customBody: (
        <Container>
          <Body color="light">
            Are you sure you want to delete this document?
          </Body>
        </Container>
      ),
    })
  }, [closeModal, deleteKnowledgebase, documentId, isPending, showModal])

  return (
    <Body textAlign="end">
      <Button
        baseDataAttribute={`-delete`}
        displayAsText
        label="Delete"
        onClick={onClickDelete}
        action="danger"
      />
    </Body>
  )
}

export default DeleteKnowledgebaseDocument
