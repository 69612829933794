import React, { useState } from 'react'
import styled from 'styled-components'

import Checkbox from './CheckboxButton'

export enum CheckboxGroupVariants {
  HORIZONTAL = 'horizontal',
  DEFAULT = 'default',
}

const StyledList = styled.ul<{ variant?: CheckboxGroupVariants }>(
  ({ theme, variant }) => ({
    ...(variant === CheckboxGroupVariants.HORIZONTAL && {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      gap: theme.space(4),
    }),
    paddingLeft: 0,
  })
)

const StyledListItem = styled.li(({ theme }) => ({
  listStyleType: 'none',
  marginTop: theme.space(0.5),
  marginBottom: theme.space(0.5),
}))

export interface CheckboxOption {
  id: string
  label: string
  value: string
  disabled?: boolean
}

export interface CheckboxButtonGroupProps {
  checkboxesOptions: CheckboxOption[]
  group: string
  initialValues: string[]
  variant?: CheckboxGroupVariants
  getValues?: (values: string[]) => void
}

const CheckboxButtonGroup: React.FC<CheckboxButtonGroupProps> = ({
  checkboxesOptions,
  group,
  initialValues = [],
  variant,
  getValues,
}) => {
  const [currentSelectedValues, setCurrentSelectedValues] =
    useState(initialValues)

  const handleCheckboxChange = (value: string) => {
    const newSelectedValues = currentSelectedValues.includes(value)
      ? currentSelectedValues.filter((v) => v !== value)
      : [...currentSelectedValues, value]

    setCurrentSelectedValues(newSelectedValues)
    getValues?.(newSelectedValues)
  }

  return (
    <StyledList variant={variant}>
      {checkboxesOptions.map(({ label, id, value, disabled }) => (
        <StyledListItem key={id}>
          <Checkbox
            label={label}
            id={id}
            value={value}
            group={group}
            onChange={() => handleCheckboxChange(value)}
            checked={currentSelectedValues.includes(value)}
            disabled={disabled}
          />
        </StyledListItem>
      ))}
    </StyledList>
  )
}

export default CheckboxButtonGroup
