import { useMutation } from '@tanstack/react-query'

import { useApiClient } from 'src/api/hooks/useApiClient'

interface StartKnowledgebaseUploadParams {
  documents: {
    fileName: string
    mimeType: string
  }[]
}

interface StartKnowledgebaseUploadResponse {
  documents: {
    signedUrl: string
    path: string
  }[]
}

export const useStartKnowledgebaseUpload = (locationId: number) => {
  const { getClient } = useApiClient()

  return useMutation({
    mutationFn: async (params: StartKnowledgebaseUploadParams) => {
      const client = await getClient()

      return client
        .post<StartKnowledgebaseUploadResponse>(
          `/locations/${locationId}/knowledge-base/file-upload`,
          {
            documents: params.documents,
          }
        )
        .then((r) => r.data)
    },
  })
}
