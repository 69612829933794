import axios from 'axios'
import { useCallback, useState } from 'react'
import { toast } from 'react-toastify'

import {
  useCompleteKnowledgebaseUpload,
  useStartKnowledgebaseUpload,
} from 'src/api'
import { useLocationContext } from 'src/contexts/LocationContext'

export const useUploadKnowledgebase = () => {
  const { locationId } = useLocationContext()
  const { mutateAsync: startKnowledgebaseUpload } =
    useStartKnowledgebaseUpload(locationId)
  const { mutateAsync: completeKnowledgebaseUpload } =
    useCompleteKnowledgebaseUpload(locationId)
  const [isLoading, setIsLoading] = useState(false)

  const uploadKnowledgebase = useCallback(
    async (files: FileList | null) => {
      setIsLoading(true)
      const selectedFiles = Array.from(files || [])

      const payload = selectedFiles.map((f) => ({
        fileName: f.name,
        mimeType: f.type,
      }))

      const { documents } = await startKnowledgebaseUpload({
        documents: payload,
      })

      for (const fileToUpload of selectedFiles) {
        const attachmentData = documents.find((d) =>
          d.path.includes(fileToUpload.name)
        )

        if (!attachmentData) {
          continue
        }

        try {
          await axios.put(attachmentData.signedUrl, fileToUpload, {
            headers: {
              'Content-Type': fileToUpload.type,
            },
          })
        } catch {
          toast.warn(`Failed to upload ${fileToUpload.name}`)
        }
      }

      await completeKnowledgebaseUpload({
        documents: documents.map((d) => ({
          path: d.path,
        })),
      })

      setIsLoading(false)
    },
    [completeKnowledgebaseUpload, startKnowledgebaseUpload]
  )

  return { uploadKnowledgebase, isLoading }
}
