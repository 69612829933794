import { useQuery } from '@tanstack/react-query'

import { useApiClient } from 'src/api/hooks/useApiClient'
import { Knowledgebase, Paginated } from 'src/api/types'

export interface GetKnowledgebaseParams {
  locationId: number
}

export const useGetKnowledgebase = ({ locationId }: GetKnowledgebaseParams) => {
  const { getClient } = useApiClient()

  return useQuery({
    queryKey: [
      'knowledge-base',
      {
        locationId,
      },
    ] as const,
    queryFn: async ({ queryKey: [, params] }) => {
      return getClient()
        .then((client) =>
          client.get<Paginated<Knowledgebase>>(
            `/locations/${params.locationId}/knowledge-base`
          )
        )
        .then((r) => r.data)
    },
  })
}
