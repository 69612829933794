import styled from 'styled-components'

export const StyledContainer = styled.div(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.space(6),
}))

export const Form = styled.form(({ theme }) => ({
  width: '100%',
  display: 'grid',
  gap: theme.space(6),
}))

export const StyledButtonsContainer = styled.div(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  gap: theme.space(6),
}))
