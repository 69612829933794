import { useMutation, useQueryClient } from '@tanstack/react-query'

import { useApiClient } from 'src/api/hooks/useApiClient'

interface DeleteKnowledgebaseParams {
  documentId: number
}

export const useDeleteKnowledgebase = (locationId: number) => {
  const { getClient } = useApiClient()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (params: DeleteKnowledgebaseParams) => {
      const client = await getClient()

      await client.delete(
        `/locations/${locationId}/knowledge-base/${params.documentId}`
      )
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [
          'knowledge-base',
          {
            locationId,
          },
        ],
      })
    },
  })
}
