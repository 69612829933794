import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import * as yup from 'yup'

import {
  Form,
  StyledButtonsContainer,
  StyledContainer,
} from './StyledComponents'
import {
  FeedbackReaction,
  useCreateFeedback,
} from 'src/api/hooks/mutations/useCreateFeedback'
import useConversationsListContext from 'src/contexts/ConversationsListContext'
import { useLocationContext } from 'src/contexts/LocationContext'
import useModalNotificationsContext from 'src/contexts/ModalNotificationsContext'
import { Button } from 'src/stories/Button'
import TextArea from 'src/stories/TextArea'
import { Heading } from 'src/stories/typography'
import logger from 'src/utils/logger'

const formSchema = yup.object({
  feedback: yup.string().required('Feedback is required'),
})

type FormSchema = yup.InferType<typeof formSchema>

interface FeedbackModalProps {
  eventId: number
  dataCy: string
}

interface SendFeedbackInterface {
  feedback: string
}

const PositiveFeedbackModal: React.FC<FeedbackModalProps> = ({
  eventId,
  dataCy,
}) => {
  const { closeModal } = useModalNotificationsContext()
  const { locationId } = useLocationContext()
  const { openConversationId } = useConversationsListContext()
  const { isPending, mutateAsync: createFeedback } =
    useCreateFeedback(locationId)

  const {
    handleSubmit,
    formState: { errors, isSubmitting },
    register,
    clearErrors,
  } = useForm<FormSchema>({
    resolver: yupResolver(formSchema),
  })

  const onSubmit = handleSubmit(async ({ feedback }) => {
    try {
      await sendFeedback({
        feedback,
      })
      toast.success('Feedback submitted')
    } catch (error) {
      logger.error('Failed to submit feedback', { error })
      toast.error('Failed to submit feedback')
    }
  })

  const onSkip = async () => {
    try {
      clearErrors()
      await sendFeedback({
        feedback: 'No Additional Feedback Provided',
      })
    } catch (error) {
      logger.error('Failed to submit feedback', { error })
    }
  }

  const sendFeedback = async ({ feedback }: SendFeedbackInterface) => {
    if (!openConversationId) {
      throw new Error('No open conversation ID found')
    }

    await createFeedback({
      reaction: FeedbackReaction.POSITIVE,
      conversationId: openConversationId,
      categories: [],
      eventId,
      feedback,
    })

    closeModal()
  }

  const baseInputProps = {
    errors,
    verticallySpaced: false,
  }

  const isDisabled = isSubmitting || isPending

  return (
    <StyledContainer>
      <Heading size="extraLarge" as={'h2'}>
        Great, tell us more!
      </Heading>
      <Form id={dataCy} onSubmit={onSubmit}>
        <TextArea
          label="What went well?"
          placeholder="Please share your thoughts..."
          rows={6}
          {...register('feedback')}
          {...baseInputProps}
        />
        <StyledButtonsContainer>
          <Button
            outline={true}
            type="button"
            label="Skip"
            disabled={isDisabled}
            onClick={onSkip}
          />

          <Button
            action="primary"
            type="submit"
            label="Submit Feedback"
            disabled={isDisabled}
          />
        </StyledButtonsContainer>
      </Form>
    </StyledContainer>
  )
}

export default PositiveFeedbackModal
