import React from 'react'

import { Body } from 'src/stories/typography'

const KnowledgebaseDetails = () => {
  return (
    <div>
      <Body>
        Your AI receptionist will use this information to provide accurate
        answers, handle customer inquiries, and assist with tasks like
        appointment scheduling or FAQs.
      </Body>
      <Body>Examples of helpful knowledge base entries include:</Body>
      <ul>
        <li>
          <Body>Service details</Body>
        </li>
        <li>
          <Body>Pricing or estimates</Body>
        </li>
        <li>
          <Body>Policies</Body>
        </li>
        <li>
          <Body>FAQs</Body>
        </li>
      </ul>

      <Body customColor="primary_2" fontWeight="bold">
        Accepted file formats: .pdf, .docx, .txt
      </Body>
    </div>
  )
}

export default KnowledgebaseDetails
