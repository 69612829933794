import { useMutation, useQueryClient } from '@tanstack/react-query'

import { useApiClient } from 'src/api/hooks/useApiClient'

export const useDeleteLocationPaymentMethod = (locationId: number) => {
  const { getClient } = useApiClient()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (paymentMethodId: string) => {
      const client = await getClient()

      await client.delete(
        `/locations/${locationId}/billing/payment-methods/${paymentMethodId}`
      )
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['location-payment-methods', { locationId }],
      })
    },
  })
}
