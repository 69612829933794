import styled from 'styled-components'

import { Body, Heading } from 'src/stories/typography'
const StyledContainer = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.space(4),
  marginBottom: theme.space(8),
}))

const UpdateCCInformationModal = () => {
  return (
    <StyledContainer>
      <Heading size="extraLarge" customColor="primary_1">
        Update Your Payment Info—It’s Quick and Easy!
      </Heading>
      <Body>
        We’re making improvements to bring you new self-serve features in the
        messaging hub - starting with payment management. Please re-enter your
        payment information to keep your account up to date.
      </Body>
      <Body>
        This is purely maintenance and won’t affect your current plan or renewal
        date. More convenient tools are on the way!
      </Body>
    </StyledContainer>
  )
}

export default UpdateCCInformationModal
